<template>
  <div class="modal fade" id="staticBackdrop" ref="static-backdrop" data-bs-backdrop="static" tabindex="-1"
       aria-labelledby="staticBackdropLabel" aria-hidden="true" role="dialog">
    <div class="modal-dialog modal-xl">
      <div class="modal-content rounded-5">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title" id="staticBackdropLabel"><!--Modal title--></h5>
          <div class="d-flex ms-auto gap-2">
            <button type="button" class="border-0 rounded-pill" @click="onEdit">{{ $t('수정') }}</button>
            <button type="button" class="border-0 rounded-pill" :disabled="isDisabledPrintButton"
                    @click="onPrint">{{ $t('프린트') }}
            </button>
            <button type="button" class="btn-close m-auto rounded-circle" data-bs-dismiss="modal"
                    aria-label="Close"></button>
          </div>
        </div>
        <div class="modal-body">
          <!-- Carousel wrapper -->
          <div class="carousel carousel-dark slide " id="carouselDTMS" ref="carousel" data-bs-interval="false">
            <!-- Slides -->
            <div class="carousel-inner mb-2">
              <template v-for="(item, index) in selectedItem?.DTMS_JSON?.items" :key="index">
                <div class="carousel-item" :class="{ active: index === 0 }">
                  <canvas class="d-block m-auto border rounded-5"
                          :id="`preview-canvas-${selectedItem.FILE_KEY}-${index}`"
                          v-bind:aria-label="item.title"
                          role="img"></canvas>
                </div>
              </template>
              <template v-if="isPublished">
                  <span class="publish-icon">
                    <!-- 퍼블리시 아이콘 -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                        <circle cx="40" cy="40" r="38.3875" fill="#EA5414" stroke="white" stroke-width="3.225"/>
                        <path d="M39.1797 40C41.8319 40 44.3754 38.9464 46.2508 37.0711C48.1261 35.1957 49.1797 32.6522 49.1797 30C49.1797 27.3478 48.1261 24.8043 46.2508 22.9289C44.3754 21.0536 41.8319 20 39.1797 20C36.5275 20 33.984 21.0536 32.1086 22.9289C30.2333 24.8043 29.1797 27.3478 29.1797 30C29.1797 32.6522 30.2333 35.1957 32.1086 37.0711C33.984 38.9464 36.5275 40 39.1797 40V40Z" stroke="white" stroke-width="4.8375" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M53.5997 47.48L46.5197 54.56C46.2397 54.84 45.9797 55.36 45.9197 55.74L45.5397 58.44C45.3997 59.42 46.0797 60.1 47.0597 59.96L49.7597 59.58C50.1397 59.52 50.6797 59.26 50.9397 58.98L58.0197 51.9C59.2397 50.68 59.8197 49.26 58.0197 47.46C56.2397 45.68 54.8197 46.26 53.5997 47.48V47.48Z" stroke="white" stroke-width="4.8375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M52.5801 48.5C53.1801 50.66 54.8601 52.34 57.0201 52.94" stroke="white" stroke-width="4.8375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22 60C22 52.26 29.7 46 39.18 46C41.26 46 43.26 46.3 45.12 46.86" stroke="white" stroke-width="4.8375" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                  </span> <!-- 퍼블리쉬 아이콘 -->
              </template>
            </div>
            <!-- Slides -->
            <!-- Controls -->
            <div class="d-flex justify-content-center gap-3">
              <control-button class="control-prev-icon" data-bs-target="#carouselDTMS" data-bs-slide="prev" :disabled="isSinglePage"/>
              <p class="contNum">
                <span class="rounded-5 px-3 py-1">{{ currentIndex + 1 }}/{{ selectedItem?.DTMS_JSON?.items?.length }}</span>
              </p>
              <control-button class="control-next-icon" data-bs-target="#carouselDTMS" data-bs-slide="next" :disabled="isSinglePage"/>
            </div>
            <!-- Controls -->
          </div>
          <!-- Carousel wrapper -->
        </div>
      </div>
    </div>
  </div>
  <device @initialize-dot-pad="initializeDotPad"/>
  <dot-pad320-key ref="dot-pad320-key"
                  @twenty-cells-left-panning="twentyCellsLeftPanning"
                  @twenty-cells-right-panning="twentyCellsRightPanning"
                  @previous-page="previousPage"
                  @next-page="nextPage"
                  @refresh="refresh"/>
</template>

<script>
import {defineAsyncComponent} from "vue";
import Modal from "bootstrap/js/dist/modal";
import Carousel from "bootstrap/js/dist/carousel";
import {router} from "@/routes/index.js";
import cloneDeep from "lodash-es/cloneDeep.js";
import debounce from "lodash-es/debounce.js";
import throttle from "lodash-es/throttle.js";
import {DotpadList} from "@/components/dtms-editor-v1.7/js/DotpadList.js";
import DTMSCanvas from "@/js/DTMSCanvas";
import ControlButton from "@/components/atoms/ControlButton";
import {$axios, $session} from "dot-framework";

const Device = defineAsyncComponent(() => import("@/components/dtms-editor-v1.7/dialog/DeviceDialog.vue"));
const DotPad320Key = defineAsyncComponent(() => import("@/views/drive/dot-pad/DotPad320Key.vue"));
const userNo = $session.getUserNo();

export default {
  name: "Preview",
  components: {
    ControlButton,
    Device,
    DotPad320Key,
  },
  props: {
    selectedItem: Object
  },
  mounted() {
    const modalEl = this.$refs["static-backdrop"];
    const carouselEl = this.$refs["carousel"];

    this.modal = Modal.getOrCreateInstance(modalEl);
    this.carousel = Carousel.getOrCreateInstance(carouselEl);
    // This event fires immediately when the show instance method is called. If caused by a click, the clicked element is available as the relatedTarget property of the event.
    modalEl.addEventListener('show.bs.modal', () => {
      // 슬라이드 첫번째 페이지 선택되도록 초기화
      this.carousel.to(0);
      this.$nextTick(() => this.createPreviewDTMSItems())
        .then(() => this.initializeDotPads());
    });
    // This event is fired immediately when the hide instance method has been called.
    modalEl.addEventListener('hide.bs.modal', () => {
      this.downAllCellsInDotPad();
      this.dotPadKeyEventUnbound();
    });
    // Add an event listener for the slide event
    carouselEl.addEventListener("slide.bs.carousel", debounce(event => {
      // Get the current index from the event object
      this.currentIndex = event.to;
      this.$nextTick(() => this.createPreviewDTMSItems());
    }, 100));
  },
  unmounted() {
    this.modal = null;
    this.carousel = null;
  },
  data() {
    return {
      modal: null,
      carousel: null,
      currentIndex: 0,
      canvasWidth: 900,   // 캔버스 너비
      canvasHeight: 600,  // 캔버스 높이
      pixels: null,
      brailleText: null,
      brailleTextIndex: [],
      brailleMaxTextIndex: 0,
      isDisabledPrintButton: false
    }
  },
  computed: {
    isSinglePage() {
      return this.selectedItem?.DTMS_JSON?.items?.length === 1;
    },
    isPublished() {
      return (this.selectedItem?.DRIVER_KIND === 'P' && this.selectedItem?.PUBLISH_COUNT > 0) || (this.selectedItem?.DRIVER_KIND === 'D' && this.selectedItem?.REG_USER_NO === userNo);
    },
  },
  watch: {
    selectedItem() {
      this.pixels = null;
      this.brailleText = null;
      this.brailleMaxTextIndex = 0;
    }
  },
  methods: {
    // 미리보기 DTMS 목록 생성
    createPreviewDTMSItems() {
      const DTMSJson = this.selectedItem?.DTMS_JSON;

      if (DTMSJson) {
        const items = cloneDeep(DTMSJson.items);
        const canvasWidth = this.canvasWidth;
        const canvasHeight = this.canvasHeight;
        const pixelArr = DTMSCanvas.getPixelArray(items[this.currentIndex]);
        const params = {
          pixelArr: pixelArr,
          canvasId: `preview-canvas-${this.selectedItem.FILE_KEY}-${this.currentIndex}`,
          canvasWidth,
          canvasHeight
        };
        const canvas = DTMSCanvas.createCanvas(params.canvasId, params.canvasWidth, params.canvasHeight);
        canvas.add(DTMSCanvas.drawGridOnTheCanvas(params.canvasWidth, params.canvasHeight));
        canvas.add(DTMSCanvas.drawTactileDataOnTheCanvas(params.pixelArr, params.canvasWidth, params.canvasHeight));

        this.pixels = pixelArr;
      }
    },
    onEdit() {
      const params = {
        TYPE: this.$store.getters['drive/driverKind'],
        DTM_NO: this.selectedItem.FILE_KEY,
        DTM_GROUP_NO: this.selectedItem.PARENT_GROUP_NO,
        DTM_NAME: this.selectedItem.FILE_NAME,
      };
      router.push({name: "canvas", query: params})
        .then(this.modal.hide());
    },
    showConnectDotPadDialog() {
      const deviceDialog = Modal.getOrCreateInstance(document.getElementById("connectDotPadDialog"));
      deviceDialog.show();
    },
    async fetchNumberOfPagesToBraille(dtmsJson, totalPages) {
      const lang = dtmsJson?.lang || "english";
      const langOption = dtmsJson?.lang_option || "2";
      const currentPage = this.currentIndex + 1;
      const params = {
        "LANGUAGE": lang,
        "OPTION": langOption,
        "CELL": "20",
        "TEXT": `${currentPage}/${totalPages}`
      }
      let url = "/braille-app/v1/braille/translation-console";

      switch (lang) {
        case "czech":
        case "danish":
        case "finnish":
        case "french":
        case "german":
        case "greek":
        case "italian":
        case "kazakh":
        case "norwegian":
        case "polish":
        case "portuguese":
        case "russian":
        case "spanish":
        case "swedish":
        case "thai":
        case "vietnamese":
          url = "/braille-app/v1/braille/translation-liblouis";
          params.PIN = "6";
          break;
      }

      const response = await $axios.post(url, params);

      if (response.status === 200) {
        return response.data.BRAILLE_RESULT?.replace(/\s/g, "")
      }

      return "";
    },
    async updateBrailleTextAndBrailleMaxTextIndex() {
      const ZERO_HEX_CHAR = "0";
      const PADDING_LENGTH = 40;
      const endingSubstring = "0".repeat(PADDING_LENGTH);
      const dtmsJson = this.selectedItem?.DTMS_JSON;
      const items = dtmsJson?.items;
      const totalPages = items?.length;
      const brailleText = items?.[this.currentIndex]?.text?.data ?? "";

      const numberOfPages = await this.fetchNumberOfPagesToBraille(dtmsJson, totalPages);
      let brailleData = `${numberOfPages}00${brailleText}`; // 페이지 번호 + (빈칸) + 점자
      // Calculate padding count once
      let paddingCount = PADDING_LENGTH - (brailleData.length % PADDING_LENGTH);

      brailleData += ZERO_HEX_CHAR.repeat(paddingCount);

      // If the last page is blank
      if (brailleData.endsWith(endingSubstring)) {
        // Remove trailing 0's in multiples of 40
        brailleData = brailleData.replace(/0{40}$/, '');

        // Calculate padding again
        paddingCount = PADDING_LENGTH - (brailleData.length % PADDING_LENGTH);

        if (paddingCount % PADDING_LENGTH !== 0) {
          brailleData += ZERO_HEX_CHAR.repeat(paddingCount);
        }
      }

      this.updateBrailleText(brailleData);
    },
    updateBrailleText(brailleData) {
      this.brailleText = brailleData;
      this.brailleMaxTextIndex = Math.ceil(this.brailleText.length / 40);
    },
    onPrint: throttle(async function () {
      const dotPadList = DotpadList.getList();

      if (dotPadList.length === 0) {
        this.showConnectDotPadDialog();
        return;
      }
      // 프린트 버튼 비활성화
      this.disableButtonForThreeSeconds();
      // 닷 패드 출력
      await this.printToDotPads();
    }, 3000),
    disableButtonForThreeSeconds() {
      const dotpadList = DotpadList.getList();
      if (dotpadList.length === 0) {
        return;
      }
      // 프린트 버튼 비활성화
      this.isDisabledPrintButton = true;
      // 3초 후 프린트 버튼 활성화
      setTimeout(() => {
        this.isDisabledPrintButton = false;
      }, 3000);
    },
    printToDotPad(dotPad) {
      const dotPadList = DotpadList.getList();
      const padIndex = dotPadList.indexOf(dotPad);
      // Description 처음부터 출력
      this.brailleTextIndex[padIndex] = 0;

      if (dotPad.connected) {
        dotPad.dotpadSDK.Load_mapFile(this.pixels);
        dotPad.dotpadSDK.Make_DTM_Data();
        dotPad.dotpadSDK.sendPixelPattern();

        if (this.brailleText.length) {
          console.log(`this.brailleText: ${this.brailleText}`);
          dotPad.dotpadSDK.sendText(this.brailleText, this.brailleTextIndex[padIndex]);
        } else {
          dotPad.dotpadSDK.sendText("0".repeat(40), this.brailleTextIndex[padIndex]);
        }
      }
    },
    async printToDotPads() {
      const dotPadList = DotpadList.getList();
      await this.updateBrailleTextAndBrailleMaxTextIndex();

      for (const dotPad of dotPadList) {
        this.printToDotPad(dotPad);
      }
    },
    downAllCellsInDotPad() {
      const dotPadList = DotpadList.getList();

      for (let i in dotPadList) {
        const dotPad = dotPadList[i];
        if (dotPad.connected) {
          dotPad.dotpadSDK.downAllCell();
        }
      }
    },
    dotPadKeyEventUnbound() {
      const dotPadList = DotpadList.getList();

      for (let i in dotPadList) {
        const dotPad = dotPadList[i];
        if (dotPad.connected) {
          dotPad.KeyInputCallbackFtn = null;
        }
      }
    },
    initializeDotPad(dotPad) {
      if (dotPad.connected) {
        dotPad.KeyInputCallbackFtn = this.$refs["dot-pad320-key"]?.keyEventCallback;
      }
    },
    initializeDotPads() {
      const dotPadList = DotpadList.getList();

      for (const dotPad of dotPadList) {
        this.initializeDotPad(dotPad);
      }
    },
    twentyCellsLeftPanning(dotPad) {
      const padIndex = DotpadList.getList().indexOf(dotPad);

      if (this.brailleTextIndex[padIndex] > 0) {
        this.brailleTextIndex[padIndex]--;
      }
      dotPad.dotpadSDK.sendText(this.brailleText, this.brailleTextIndex[padIndex]);
    },
    twentyCellsRightPanning(dotPad) {
      const padIndex = DotpadList.getList().indexOf(dotPad);

      if (this.brailleTextIndex[padIndex] < this.brailleMaxTextIndex - 1) {
        this.brailleTextIndex[padIndex]++;
      }
      dotPad.dotpadSDK.sendText(this.brailleText, this.brailleTextIndex[padIndex]);
    },
    previousPage() {
      this.carousel.prev();
      this.$nextTick(() => {
        const unwatch = this.$watch('pixels', () => {
          this.onPrint();
          // 감시자를 중지
          unwatch();
        });
      });
    },
    nextPage() {
      this.carousel.next();
      this.$nextTick(() => {
        const unwatch = this.$watch('pixels', () => {
          this.onPrint();
          // 감시자를 중지
          unwatch();
        });
      });
    },
    refresh(dotPad) {
      this.updateBrailleTextAndBrailleMaxTextIndex()
        .then(() => {
          this.printToDotPad(dotPad);
        });
    },
  }
}
</script>

<style scoped>
.modal-header button {
  height: 48px;
  padding: 0 36px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background: #AAABAB;
  opacity: 1;
}

.modal-header button:hover {
  opacity: 0.8;
}

.modal-header button:active {
  opacity: 1 !important;
}

.modal-header button:disabled {
  opacity: 0.4;
}

.modal-header .btn-close {
  cursor: pointer;
  width: 48px;
  height: 48px;
  background: #EA5414 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  padding: 0;
  opacity: 1;
}

.modal-body .contNum {
  font-size: 20px !important;
  padding: 0 10px;
  background-color: #D9D9D9;
  height: 48px;
  border-radius: 40px;
}

.modal-body .contNum span {
  line-height: 48px;
}

.publish-icon {
  width: 5rem;
  height: 5rem;
  background: #EA5414;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  position: absolute;
  bottom: 15px;
  right: 117px;
}
</style>
